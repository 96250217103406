<template>
  <div class="h-full">
    <Scrollbar>
      <Table auto-loading :options="handleOptions" :loadData="handleLoadData" title="奖章设置" ref="table">
        <template #search>
          <el-form :inline="true" :model="formSearch" class="demo-form-inline">
            <el-form-item label="奖章名称">
              <el-input v-model="formSearch.name" placeholder="奖章名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleRefresh">查询</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #action>
          <div>
            <el-button type="primary" icon="el-icon-plus" @click="handleCreate">添加</el-button>
<!--            <el-button type="primary" icon="el-icon-edit" @click="handleBatchDelete">批量删除</el-button>-->
          </div>
        </template>
        <template #table-item-image="{row}">
          <el-image style="width: 30px; height: 30px" :src="row.image" :preview-src-list="[row.image]"></el-image>
        </template>
        <template #handle="{index,row}">
          <el-button type="text" icon="el-icon-edit" @click="handleUpdate(row)">修改</el-button>
          <el-button type="text" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
        </template>
      </Table>
    </Scrollbar>
  </div>
</template>
<script type="text/javascript">
import edit from './components/medalEdit.vue'
export default {
  name: "medal",
  data() {
    return {
      options: {
        header: [],
        search: []
      },
      //搜索表单结构
      formSearch: {
        name: '',
      },
    };
  },
  methods: {
    handleOptions(table) {
      console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/award/medal").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}, search = {}) {
      //获取分页数据结构
      this.$cloud.get("card/list", {size, page,...this.formSearch}).then(res => {
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },


    // 添加
    handleCreate(){
        this.$cloud.dialog({
          title: '添加奖章',
          data: {},
          component: edit,
          success: '添加成功!',
          warning: '添加失败!',
          refresh: this.handleRefresh
        })
    },
    //修改
    handleUpdate(emit){
      this.$cloud.dialog({
        title: '更新奖章',
        data: emit,
        component: edit,
        success: '更新成功!',
        warning: '更新失败!',
        refresh: this.handleRefresh
      })
    },

    // 删除
    handleDelete(item){
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cloud.post("card/remove", {id: item.id}).then(() => {
          this.$message.success('删除成功！')
          this.handleRefresh()
        })
      })
    },

    // 刷新
    handleRefresh() {
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    }
  },
};
</script>
<style lang="scss"></style>
