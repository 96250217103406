<!--
 * ======================================
 * 说明： 奖章 添加 修改
 * 作者： Silence
 * 文件： medalEdit.vue
 * 日期： 2023/8/4 22:25
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
    <el-form ref="editForm" :model="editForm" :rules="rules" label-width="80px">
      <el-form-item label="奖章名称" prop="name">
        <el-input v-model="editForm.name"></el-input>
      </el-form-item>
      <el-form-item label="奖章图片" prop="image">
        <el-upload
          style="overflow: hidden"
          :limit="1"
          action="#"
          list-type="picture-card"
          :show-file-list="false"
          :http-request="onUploadPicture"
        >
          <img v-if="editForm.image" :src="editForm.image" class="avatar w-full" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
    </el-form>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      value:Object,
    },
    data() {
      return {
        visible:false,
        type:'create',
        editForm:{
          name:'',
          image:''
        },
        rules:{
          name:[
            { required: true, message: '请输入奖章名称', trigger: 'blur' },
          ],
          image:[
            { required: true, message: '请上传奖章图片', trigger: 'blur' },
          ],
        },
      }
    },
    computed: {},
    created() {},
    mounted() {
      if(this.value.id){
        this.handleLoadForm(this.value.id)
      }else{
        this.editForm = {
          name:'',
          image:''
        }
      }
    },
    methods: {
      onUploadPicture(file) {
        let params = {
          file: file.file,
        };
        this.loading = true;
        this.$cloud
          .upload("files/upload", params)
          .then((res) => {
            this.loading = false;
            this.editForm.image = res.url;
          })
          .catch((err) => {
            this.loading = false;
          });
      },
  
      // 加载表单数据
      handleLoadForm(id){
        this.$cloud.get('card/detail',{id}).then(res=>{
          this.editForm = res
        })
      },
  
      // 提交数据
      handleSubmit(){
        return new Promise((resolve,reject)=>{
          this.$refs['editForm'].validate((valid)=>{
            if(valid){
              let api = this.editForm.id ? 'card/edit' : 'card/create'
              this.$cloud.post(api,this.editForm).then(()=>{
                resolve(true)
              }).catch(()=>{
                reject(false)
              })
            }else{
              reject(false)
            }
          })
        })
      }
    },
  }
</script>
<style lang="scss" scoped></style>